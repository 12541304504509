"use client";

import React, { createContext, useContext, useState, useCallback } from "react";

interface ScheduledTweetsContextType {
  isRefreshing: boolean;
  setIsRefreshing: React.Dispatch<React.SetStateAction<boolean>>;
  refreshScheduledTweets: () => void;
}

const ScheduledTweetsContext = createContext<
  ScheduledTweetsContextType | undefined
>(undefined);

export function ScheduledTweetsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const refreshScheduledTweets = useCallback(() => {
    setIsRefreshing(true);
  }, []);

  return (
    <ScheduledTweetsContext.Provider
      value={{ isRefreshing, setIsRefreshing, refreshScheduledTweets }}
    >
      {children}
    </ScheduledTweetsContext.Provider>
  );
}

export function useScheduledTweets() {
  const context = useContext(ScheduledTweetsContext);
  if (context === undefined) {
    throw new Error(
      "useScheduledTweets must be used within a ScheduledTweetsProvider"
    );
  }
  return context;
}
