"use client";

import { usePathname } from "next/navigation";
import Navbar from "./navbar";

export default function NavbarWrapper() {
  const pathname = usePathname();
  const hideNavbarPaths = ["/user-personas-generator", "/playground-mock"];
  const showNavbar = !hideNavbarPaths.some((path) => pathname.startsWith(path));

  return showNavbar ? <Navbar /> : null;
}
