"use client";

import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
  SignUpButton,
} from "@clerk/nextjs";
import AOS from "aos";
import "aos/dist/aos.css";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { useRouter } from "next/navigation";
import { Badge } from "@/components/ui/badge";
import { ArrowRight } from "lucide-react";

export default function Navbar() {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const getBackgroundColor = () => {
    if (!mounted) return "bg-gray-100/70"; // Default to light theme
    return theme === "dark" ? "bg-zinc-900" : "bg-gray-100/70";
  };

  const getBorderColor = () => {
    if (!mounted) return "border-gray-300"; // Default to light theme
    return theme === "dark" ? "border-gray-700/30" : "border-gray-300";
  };

  const getTextColor = () => {
    if (!mounted) return "text-gray-900 hover:text-black"; // Default to light theme
    return theme === "dark"
      ? "text-gray-200 hover:text-white"
      : "text-gray-900 hover:text-black";
  };

  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 z-50 flex justify-between items-center py-1 px-4 backdrop-filter backdrop-blur-lg
        ${getBackgroundColor()} border-b ${getBorderColor()}`}
        style={{ height: "48px" }}
      >
        <div className="flex items-center">
          <Link href="/">
            <Image
              src="/kunochi-logo.png"
              alt="Logo"
              width={32}
              height={32}
              style={{ height: "auto" }}
            />
          </Link>
          <Badge
            variant="secondary"
            className="ml-2 bg-gradient-to-r from-purple-400 to-pink-500 text-white"
          >
            BETA
          </Badge>
        </div>

        <nav>
          <ul className="flex items-center gap-x-4">
            <SignedOut>
              <li>
                <SignInButton mode="modal" fallbackRedirectUrl="/dashboard">
                  <button
                    className={`transition-colors duration-300 text-sm font-medium ${getTextColor()}`}
                  >
                    Log In
                  </button>
                </SignInButton>
              </li>
              <li>
                <SignUpButton mode="modal" fallbackRedirectUrl="/dashboard">
                  <button className="group flex justify-center items-center py-2 px-4 text-sm font-semibold rounded-lg shadow-md transition-all duration-300 bg-green-500 text-white hover:bg-green-600 hover:scale-105 hover:shadow-lg">
                    <span className="mr-2">Start Now For Free</span>
                    <ArrowRight
                      className="transition-transform group-hover:translate-x-1"
                      size={16}
                    />
                  </button>
                </SignUpButton>
              </li>
            </SignedOut>
            <SignedIn>
              <li>
                <UserButton afterSignOutUrl="/" />
              </li>
            </SignedIn>
          </ul>
        </nav>
      </div>
    </>
  );
}
